import React, { useState } from 'react'
import firebase from 'firebase';
//import { auth } from '../firebase.js'
//import { Button } from '@material-ui/core'
import './login.css';

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    async function signInEmailRegistro() {
        try {
            const result = await firebase.auth().createUserWithEmailAndPassword(email,password);
            console.log(result);
        }catch(error) {
            console.log(error);
            if (error.code === 'auth/email-already-in-use') {
                alert('Correo ya existe');
            }
        }
        user = firebase.auth().currentUser;
    }
    async function signInEmail() {
        try {
            const result = await firebase.auth().signInWithEmailAndPassword(email, password);
            console.log(result);
        }catch(error) {
            console.log(error);
            if (error.code === 'auth/wrong-password') {
                alert('Error de contraseña');
            }
            if (error.code === 'auth/user-not-found') {
                alert('Este usuario no existe haga click en registrase');
            }
        }
        user = firebase.auth().currentUser;
    }
    let user = firebase.auth().currentUser;
    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
            <div>
                <p>Bienvenid@ a tu rincon de fonoaudiologia, introduzca a continuación los datos del paciente</p>
                <br/>
                {user? user.email: ''}
                <label>
                    Correo:
                </label>
                    <div class="input-group mb-3 p-1">
                    <input type="email" name="correo"  class="form-control" onChange={(ev => setEmail(ev.target.value))}/>
                    </div>
                <label>
                    Contraseña:
                </label>
                    <div class="input-group mb-3 p-1">
                    <input type="password" name="correo"  class="form-control" onChange={(ev => setPassword(ev.target.value))}/>
                    </div>
                <div className="noshow">    
                    <label>
                    Nombre:
                    </label>
                        <div class="input-group mb-3 p-1">
                        <input type="text" name="nombre"  class="form-control"/>
                        </div>
                    <label>
                        Apellido:
                    </label>
                        <div class="input-group mb-3 p-1">
                        <input type="text" name="apellido"  class="form-control"/>
                        </div>
                    <label>
                        Año nacimiento:
                    </label>
                    <div class="input-group mb-3 p-1">
                    <input type="number" name="apellido"  class="form-control"/>
                    </div>
                    
                    <label>
                        Sintomas ó dificultad que presenta:
                    </label>
                    <div class="input-group mb-3 p-1">
                        <input type="text" name="problema"  class="form-control"/>
                    </div>
                </div>
                <input type="submit" value="Iniciar sesion"  class="form-control" onClick={signInEmail}/>
                <br/>
                <input type="submit" value="Registarse"  class="form-control" onClick={signInEmailRegistro}/>

            </div>
          </div>
    )
}

export default SignIn

