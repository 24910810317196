import firebase from 'firebase'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBaDSGbdUvZA2kuwWqMIU0WmFRSCIq7KIY",
    authDomain: "fonoaudiologaft.firebaseapp.com",
    projectId: "fonoaudiologaft",
    storageBucket: "fonoaudiologaft.appspot.com",
    messagingSenderId: "399652627273",
    appId: "1:399652627273:web:5e8c245b33b0556104b098",
    measurementId: "G-XYTGJXRMFM"
  })

const db = firebaseApp.firestore()

const auth = firebase.auth()

export { db, auth }